<template>
  <MBaseModal @close="close">
    <template #title>{{ title }}</template>
    <template #body>
      <div
        class="w-full h-full flex flex-col items-center justify-center text-center bg-primary-100"
      >
        <div v-if="description" class="mb-4">{{ description }}</div>
        <div class="mx-4">
          <m-text-field
            name="time"
            type="time"
            placeholder="00:00"
            :value="time"
            class="mb-6"
            @input="time = $event"
          />
        </div>
        <p v-if="!validTime" class="mb-4 text-red-500 text-xs">
          ※ 1 分以下または 20 時間以上先の時刻は指定できません。
        </p>
        <MButton :invalid="!validTime" @click="send">決定</MButton>
      </div>
    </template>
  </MBaseModal>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/form/MButton.vue";
import MTextField from "@/components/form/MTextField.vue";
import { isValidTimerEnd } from "@/api/learning";
import dayjs from "dayjs";

@Options({
  components: {
    MBaseModal,
    MButton,
    MTextField
  },
  props: {
    title: String,
    type: String,
    description: String
  },
  emits: ["close", "send"]
})
export default class MTimePickerModal extends Vue {
  time = "00:00";
  title = "時間を入力";
  type = "time";
  description = "";

  get validTime(): boolean {
    return /^\d{2}:\d{2}$/.test(this.time) && isValidTimerEnd(this.time);
  }

  close() {
    this.$emit("close");
  }

  send() {
    if (!this.validTime) {
      return;
    }
    this.$emit("send", this.time);
    this.close();
  }

  created() {
    this.time = dayjs().format("HH:mm");
  }
}
</script>
