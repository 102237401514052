<template>
  <div class="w-full">
    <label :for="name" class="leading-7 text-sm font-medium text-gray-500">
      {{ label }}
    </label>
    <div v-if="suffix" class="flex w-full items-center">
      <input
        :type="type"
        :name="name"
        :placeholder="placeholder"
        :value="value"
        class="flex-grow h-11 py-1 bg-white rounded-md border border-white focus:border-primary-500 text-base outline-none appearance-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-300"
        :class="dynamicClasses"
        @input="$emit('input', $event.target.value)"
        @blur="onBlur"
      />
      <span class="ml-2 text-sm font-medium">{{ suffix }}</span>
    </div>
    <input
      v-else
      :type="type"
      :name="name"
      :placeholder="placeholder"
      :value="value"
      class="w-full h-11 py-1 bg-white rounded-md border border-white focus:border-primary-500 text-base outline-none appearance-none text-gray-700 px-3 leading-8 transition-colors duration-300"
      :class="dynamicClasses"
      @input="$emit('input', $event.target.value)"
      @blur="onBlur"
    />
    <p
      v-if="invalid && entered && invalidMessage"
      class="mt-1 text-red-500 text-xs"
    >
      {{ invalidMessage }}
    </p>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

type InputType =
  | "text"
  | "password"
  | "email"
  | "search"
  | "url"
  | "datetime"
  | "date"
  | "time"
  | "number";

@Options({
  emits: ["input", "enter"],
  props: {
    label: String,
    name: String,
    type: String,
    placeholder: String,
    value: String,
    suffix: String,
    invalid: Boolean,
    invalidMessage: String,
    entered: Boolean
  }
})
export default class MTextField extends Vue {
  label = "";
  name = "";
  type: InputType = "text";
  placeholder = "";
  value = "";
  suffix = "";
  invalid = false;
  invalidMessage = "";
  entered = false;

  get dynamicClasses(): string {
    const classes: string[] = [];

    if (this.invalid && this.entered) {
      classes.push("border-red-400");
    }

    return classes.join(" ");
  }

  onBlur() {
    this.$emit("enter");
  }
}
</script>
