<template>
  <div class="flex flex-col w-full">
    <span class="leading-7 text-sm font-medium text-gray-500">{{ label }}</span>
    <p class="p-3 rounded-md bg-white">
      <slot />
    </p>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    label: String
  }
})
export default class MInfoField extends Vue {
  label = "";
}
</script>
