
import { Options, Vue } from "vue-class-component";

type InputType =
  | "text"
  | "password"
  | "email"
  | "search"
  | "url"
  | "datetime"
  | "date"
  | "time"
  | "number";

@Options({
  emits: ["input", "enter"],
  props: {
    label: String,
    name: String,
    type: String,
    placeholder: String,
    value: String,
    suffix: String,
    invalid: Boolean,
    invalidMessage: String,
    entered: Boolean
  }
})
export default class MTextField extends Vue {
  label = "";
  name = "";
  type: InputType = "text";
  placeholder = "";
  value = "";
  suffix = "";
  invalid = false;
  invalidMessage = "";
  entered = false;

  get dynamicClasses(): string {
    const classes: string[] = [];

    if (this.invalid && this.entered) {
      classes.push("border-red-400");
    }

    return classes.join(" ");
  }

  onBlur() {
    this.$emit("enter");
  }
}
