import { errorLogCollectionKey, ErrorLogData } from "@/entities/error";
import { Student } from "@/entities/student";
import firebase from "firebase/app";

export async function saveErrorLog(
  student: Student | null,
  code: string,
  message: string,
  summary: string
): Promise<void> {
  const data: ErrorLogData = {
    uid: student ? student.ref.id : "",
    code,
    message,
    summary,
    timestamp: Math.floor(Date.now() / 1000)
  };

  await firebase.firestore().collection(errorLogCollectionKey).add(data);
}
