
import { Options, Vue } from "vue-class-component";
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/form/MButton.vue";
import MTextField from "@/components/form/MTextField.vue";
import { isValidTimerEnd } from "@/api/learning";
import dayjs from "dayjs";

@Options({
  components: {
    MBaseModal,
    MButton,
    MTextField
  },
  props: {
    title: String,
    type: String,
    description: String
  },
  emits: ["close", "send"]
})
export default class MTimePickerModal extends Vue {
  time = "00:00";
  title = "時間を入力";
  type = "time";
  description = "";

  get validTime(): boolean {
    return /^\d{2}:\d{2}$/.test(this.time) && isValidTimerEnd(this.time);
  }

  close() {
    this.$emit("close");
  }

  send() {
    if (!this.validTime) {
      return;
    }
    this.$emit("send", this.time);
    this.close();
  }

  created() {
    this.time = dayjs().format("HH:mm");
  }
}
