
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    label: String
  }
})
export default class MInfoField extends Vue {
  label = "";
}
