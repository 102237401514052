
import { Options, Vue } from "vue-class-component";
import MButton from "@/components/form/MButton.vue";
import MInfoField from "@/components/form/MInfoField.vue";
import MTimePickerModal from "@/components/message/MTimePickerModal.vue";
import {
  Learning,
  learningCollectionKey,
  LearningRecord
} from "@/entities/learning";
import dayjs from "dayjs";
import {
  stopLearningTimer,
  getLearning,
  updateLearningTimer,
  updateTimerLastDisplayed,
  extendLearningTimer,
  timeStringToUnixtime
} from "@/api/learning";
import { saveErrorLog } from "@/api/error";
import store, { awaitStudentLoaded } from "@/store";

@Options({
  components: {
    MButton,
    MInfoField,
    MTimePickerModal
  }
})
export default class LearningTimer extends Vue {
  learning: Learning | null = null;
  second = 0;
  paused = false;
  pageIsHidden = false;
  tickTimerId = -1;
  visibilityCheckerTimerId = -1;
  sendNewerTimerEndCheckerId = -1;
  limitOver = false;
  showTimePickerModal = false;
  checkingNewerTimerEnd = false; // 送信した新しい終了時刻の更新完了の確認待ち中であるか

  get goal(): string {
    return this.learning ? this.learning.data.goal : "-";
  }

  get endTimeText(): string {
    if (!this.learning?.data?.meta.timer) {
      // 通常ありえないが、読み込み中に表示される可能性あり
      return "--";
    }
    // 当初の開始時刻と終了時刻から算出できる分数
    const totalSeconds =
      this.learning.data.meta.timer.end - this.learning.data.timestamp;
    if (Number.isNaN(totalSeconds)) return "--";
    // 一時停止されていた分数 現在の start（一時停止中の場合、現在時刻）から前回の end の差を合算していく
    const timeText = dayjs
      .unix(this.learning.data.meta.timer.end)
      .locale("ja")
      .format("H 時 m 分");
    return `${timeText}${
      !this.limitOver
        ? `（残り約 ${Math.floor((totalSeconds - this.second) / 60)} 分）`
        : ""
    }`;
  }

  get timerText(): string {
    const minutes = Math.floor(this.second / 60);
    const hours = Math.floor(minutes / 60);
    return `${hours > 0 ? `${hours}:` : ""}${(minutes % 60)
      .toString()
      .padStart(2, "0")}:${(this.second % 60).toString().padStart(2, "0")}`;
  }

  get limitSeconds(): number {
    if (!this.learning?.data?.meta.timer) return 0;
    const limitSeconds =
      this.learning?.data?.meta.timer.end - this.learning?.data?.timestamp;
    return !Number.isNaN(limitSeconds) ? limitSeconds : 0;
  }

  get timezoneGapString(): string {
    const gapBetweenJapan = new Date().getTimezoneOffset() + 60 * 9;
    return `${gapBetweenJapan > 0 ? "+" : ""}${gapBetweenJapan / 60}`;
  }

  async changeTimerStopStatus() {
    if (
      !this.learning ||
      !this.learning.data.meta ||
      !this.learning.data.meta.timer
    ) {
      return;
    }
    this.paused = !this.paused;

    const timer = this.learning.data.meta.timer;
    const now = Math.floor(Date.now() / 1000);

    if (this.paused) {
      // タイムキーパー停止処理
      const timerStart = 0;
      const timerTracks: LearningRecord[] = [
        ...timer.tracks,
        {
          start: timer.start,
          end: now
        }
      ];
      this.learning.data.meta.timer.start = timerStart;
      this.learning.data.meta.timer.tracks = timerTracks;
      await updateLearningTimer(
        this.learning.ref,
        timerStart,
        timerTracks
      ).catch(e => {
        alert(
          `タイムキーパーの更新に失敗しました。通信環境を確認してください。`
        );
        saveErrorLog(
          store.state.student,
          e.code,
          e.message,
          "Failed to update learning timer"
        );
      });
    } else {
      // タイムキーパー再開処理
      this.learning.data.meta.timer.start = now;
      await updateLearningTimer(this.learning.ref, now, timer.tracks).catch(
        e => {
          alert(
            `タイムキーパーの更新に失敗しました。通信環境を確認してください。`
          );
          saveErrorLog(
            store.state.student,
            e.code,
            e.message,
            "Failed to update learning timer"
          );
        }
      );
    }
  }

  async updateTime() {
    if (!this.learning?.data?.meta.timer) return;
    const now = Math.floor(Date.now() / 1000);
    if (this.paused && now >= this.learning.data.meta.timer.end) {
      // 一時停止中に終了予定時刻に達した
      clearInterval(this.tickTimerId);
      this.limitOver = true;
    }
    if (this.paused) {
      return;
    }

    const trackTime = this.learning.data.meta.timer.tracks.reduce(
      (pre, current) => pre + (current.end - current.start),
      0
    );
    const totalSecond = now - this.learning.data.meta.timer.start + trackTime;
    this.second = totalSecond;
    if (now >= this.learning.data.meta.timer.end) {
      // 再生中に終了予定時刻に達した
      clearInterval(this.tickTimerId);
      this.limitOver = true;
    }
  }

  updateLastDisplayed() {
    if (!this.learning || !this.learning.data.meta.timer || this.pageIsHidden) {
      return;
    }
    updateTimerLastDisplayed(this.learning.ref).catch(e => {
      saveErrorLog(
        store.state.student,
        e.code,
        e.message,
        "Failed to update learning timer last displayed"
      );
    });
  }

  async endLearning() {
    if (!this.learning || this.checkingNewerTimerEnd) {
      return;
    }

    try {
      store.commit("SET_LOADING", true);
      store.commit("SET_LOAD_TEXT", "学習時間記録中...");
      // 最大でも終了予定時刻までの分数しか記録しない
      await stopLearningTimer(this.learning, this.second < this.limitSeconds);
      this.$router.replace(`/learning/${this.learning.ref.id}/reflection`);
    } catch (e) {
      alert(
        `学習時間の記録に失敗しました。すでに自動的に記録終了している可能性があります。`
      );
      saveErrorLog(
        store.state.student,
        e.code,
        e.message,
        "Failed to update learning timer"
      );
      this.toHome();
    } finally {
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
    }
  }

  async sendNewerTimerEnd(newerTimerEnd: string) {
    const student = store.state.student;
    if (!student || !this.limitOver || !this.learning) {
      return;
    }
    try {
      store.commit("SET_LOADING", true);
      store.commit("SET_LOAD_TEXT", "設定変更中...");
      await extendLearningTimer(
        student.ref,
        this.learning.ref.id,
        newerTimerEnd
      );
      this.showTimePickerModal = false;
      /*
        再度カウントを開始するため再読み込みするが、
        sendPostBackEvent が返ってきてもまだ learning が更新されていることを保証できないので
        ここで検証してから再読み込みする。
        */
      this.checkingNewerTimerEnd = true;
      let counter = 0;
      this.sendNewerTimerEndCheckerId = setInterval(async () => {
        const learning = await getLearning(
          student.ref
            .collection(learningCollectionKey)
            .doc(this.learning?.ref.id)
        );
        if (!learning?.data?.meta?.timer?.end) return;

        const expected = timeStringToUnixtime(
          newerTimerEnd,
          this.learning?.data?.meta?.timer?.end
        );
        const gapSecondsBetweenJapan =
          (new Date().getTimezoneOffset() + 60 * 9) * 60;
        if (
          learning?.data.meta.timer.end ===
          expected - gapSecondsBetweenJapan
        ) {
          // 取得した timer.end がここで指定された時刻と合致していたら画面を更新する
          clearInterval(this.sendNewerTimerEndCheckerId);
          location.reload();
        } else if (++counter === 20) {
          clearInterval(this.sendNewerTimerEndCheckerId);
          alert(`タイムキーパーの更新が正しく完了していない可能性があります。`);
          saveErrorLog(
            store.state.student,
            "408",
            "operation too late",
            "Failed to check Learning updated in 20 seconds"
          );
          store.commit("SET_LOADING", false);
          store.commit("SET_LOAD_TEXT", "");
          this.toHome();
        }
      }, 1000);
    } catch (e) {
      alert(`タイムキーパーの更新が正しく完了していない可能性があります。`);
      await saveErrorLog(
        student,
        e.code,
        e.message,
        "Failed to extend Learning timer.end"
      );
    } finally {
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
    }
  }

  unsubscribe() {
    if (this.tickTimerId > 0) clearInterval(this.tickTimerId);
    if (this.visibilityCheckerTimerId > 0)
      clearInterval(this.visibilityCheckerTimerId);
    if (this.sendNewerTimerEndCheckerId > 0)
      clearInterval(this.sendNewerTimerEndCheckerId);
    document.removeEventListener("visibilitychange", this.onChangeVisiblity);
    window.removeEventListener("focus", this.onFocus);
  }

  unmounted() {
    this.unsubscribe();
  }

  async created() {
    const student = await awaitStudentLoaded(store);

    const learningId = this.$route.params.learningId as string;
    if (!learningId) {
      return;
    }

    try {
      const learning = await getLearning(
        student.ref.collection(learningCollectionKey).doc(learningId)
      );
      if (!learning || !learning.data.meta.timer) {
        alert("記録中のタイムキーパーが見つかりませんでした。");
        this.$router.replace("/");
        return;
      }
      this.learning = learning;
      const trackTime = learning.data.meta.timer.tracks.reduce(
        (pre, current) => pre + (current.end - current.start),
        0
      );
      const now = Math.floor(Date.now() / 1000);
      this.limitOver = now >= (this.learning?.data?.meta?.timer?.end ?? 0);
      if (!this.limitOver) {
        if (learning.data.meta.timer.start > 0) {
          // 再生中でまだ終了予定時刻に達していない場合
          this.second = now - learning.data.meta.timer.start + trackTime;
        } else {
          // 一時停止中でまだ終了予定時刻に達していない場合
          this.second = trackTime;
          this.paused = true;
        }
        // タイムキーパー & 画面起動状態更新処理
        this.tickTimerId = setInterval(this.updateTime, 1000);
        this.visibilityCheckerTimerId = setInterval(
          this.updateLastDisplayed,
          30 * 1000
        );
      } else {
        if (learning.data.meta.timer.start > 0) {
          // 再生中にオーバーした場合
          this.second =
            learning.data.meta.timer.end -
            learning.data.meta.timer.start +
            trackTime;
        } else {
          // 一時停止中にオーバーした場合
          this.second = trackTime;
        }
        this.paused = true;
      }
    } catch (e) {
      alert(`記録中のタイムキーパーが見つかりませんでした。`);
      await saveErrorLog(
        store.state.student,
        e.code,
        e.message,
        "Failed to get learning timer"
      );
      return;
    }

    // 画面の表示状態を listen
    document.addEventListener(
      "visibilitychange",
      this.onChangeVisiblity,
      false
    );
    window.addEventListener("focus", this.onFocus);
  }

  onChangeVisiblity() {
    this.pageIsHidden = document.hidden;
  }

  /*
    他の画面から時間がたって戻ってきた場合を想定。
    強制終了済みの場合はホーム画面に戻す
    */
  async onFocus() {
    if (!store.state.student) return;
    const learning = await getLearning(
      store.state.student.ref
        .collection(learningCollectionKey)
        .doc(this.learning?.ref.id)
    );
    if (!learning?.data.meta?.timer) {
      this.unsubscribe();
      this.toHome();
    }
  }

  toHome() {
    this.$router.push("/");
  }
}
